import PageTitle from "./PageTitle";
import Container from "../components/layout/Container";
import Card from "../components/elements/Card";
import ReportsOverviewShort from "../components/reports/ReportsOverviewShort";
import ReportsGraph from "../components/reports/ReportsGraph";
import useFetchUserReports from "../actions/hooks/useFetchUserReports";
import ReportsListShort from "../components/reports/ReportsListShort";
import ExpenseCreateOptions from "../components/expenses/ExpenseCreateOptions";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExclamationCircle, faCheckCircle } from "@fortawesome/free-solid-svg-icons";
import { UserContext } from "../context/UserContext";
import { useContext } from "react";
import { useTranslation } from "react-i18next";
export default function IndexPage() {
	const { t } = useTranslation();
	const reportsResponse = useFetchUserReports();
	const { user } = useContext(UserContext);
	const isAdmin = user.user_roles.includes("administrator");

	let selectedStates = [];
	if (isAdmin) {
		selectedStates = ["processing"];
	} else {
		selectedStates = ["processing", "open"];
	}

	let filteredReports = [];
	if (reportsResponse) {
		filteredReports = reportsResponse.data.reports.filter(
			(report) =>
				selectedStates.includes(report.status) && report.from_id !== report.to_id
		);
	}

	return (
		<>
			<div>
				<PageTitle title={t("Home")} />
			</div>
			<Container>
				<div className="flex items-center justify-between">
					{/* Reports Status */}
					{filteredReports && (
						<div className="flex items-center">
							<span>
								{filteredReports.length > 0
									? `${filteredReports.length} ${t("reports unseen")}`
									: t("No reports to review")}
								<FontAwesomeIcon
									icon={filteredReports.length > 0 ? faExclamationCircle : faCheckCircle}
									className={filteredReports.length > 0 ? "text-warning ml-2" : "text-success ml-2"}
								/>
							</span>
						</div>
					)}

					{/* Expense Creation Options */}
					<div className="ml-auto">
						<ExpenseCreateOptions />
					</div>
				</div>
				<div className="border-t border-gray-300 pb-2"></div>
				<div className="flex flex-col gap-4">
					<div className="flex flex-col lg:flex-row justify-between gap-4">
						<div className="w-full lg:w-3/6 flex-1 hidden md:block">
							<Card header={t("My Reports")}>
								{reportsResponse && reportsResponse.data.reports && (
									<ReportsOverviewShort
										reports={reportsResponse.data.reports}
									/>
								)}
							</Card>
						</div>
						<div className={`w-full lg:w-3/6 ${filteredReports.length === 0 ? "hidden md:block" : ""
							}`}>
							<Card header={t("Reports requiring attention")}>
								{reportsResponse && reportsResponse.data.reports && (
									<ReportsListShort reports={reportsResponse.data.reports} />
								)}
							</Card>
						</div>
					</div>
					<div className="flex flex-col lg:flex-row justify-between gap-4">
						<div className="w-full lg:w-3/6 flex-1">
							<Card header={t("Reports by date")}>
								{reportsResponse && (
									<ReportsGraph
										reports={reportsResponse.data.reports}
										graphType="reports"
									/>
								)}
							</Card>
						</div>
						<div className="w-full lg:w-3/6 flex-1">
							<Card header={t("Expenses by date")}>
								{reportsResponse && (
									<ReportsGraph
										reports={reportsResponse.data.reports}
										graphType="euros"
									/>
								)}
							</Card>
						</div>
					</div>
				</div>
			</Container>
		</>
	);
}
