import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import useFetchArticlesByCategory from "../actions/hooks/useFetchArticlesByCategory";
import Container from "../components/layout/Container";
import Article from "../components/articles/Article";
import PageTitle from "./PageTitle";
import { useTranslation } from "react-i18next";

const HelpPage = () => {
	const { t } = useTranslation();
	const articleResponse = useFetchArticlesByCategory("help");
	const [articlelist, setArticlelist] = useState([]);
	const navigate = useNavigate();

	useEffect(() => {
		if (articleResponse) {
			setArticlelist(articleResponse);
		}
	}, [articleResponse]);

	const handleCardClick = (article) => {
		navigate(`/article/title/${article.title}`);
	};

	const articles = articlelist.map((article) => {
		return (
			<div key={article.id} className="cursor-pointer hover:opacity-90" onClick={() => handleCardClick(article)}>
				<Article article={article} />
			</div >
		);
	});

	return (
		<>
			<PageTitle title={t("Help & Resources")} />
			<Container>
				<span>{t('Questions? Find the answers by clicking a Category.')}</span>
				<div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-10 mt-4">
					{articles}
				</div>
			</Container>
		</>
	);
};

export default HelpPage;
