import { useEffect, useState, useContext } from "react";
import InputField from "../components/layout/InputField";
import useUserRegisterUuid from "../actions/hooks/useUserRegisterUuid";
import Button from "../components/elements/Button";
import { Navigate, useParams } from "react-router-dom";
import { Link } from "react-router-dom";
import Card from "../components/elements/Card";
import Container from "../components/layout/Container";
import Alert from "../components/elements/Alert";
import useShowAlert from "../actions/hooks/utility/useShowAlert";
import { UserContext } from "../context/UserContext";
import { useTranslation } from "react-i18next";
export default function InviteUserPage() {
  const { t } = useTranslation();
  const { ready, user } = useContext(UserContext);
  const [form, setForm] = useState({
    first_name: "",
    last_name: "",
    password: "",
  });

  const {
    showSuccessAlert,
    setShowSuccessAlert,
    showErrorAlert,
    setShowErrorAlert,
  } = useShowAlert();

  const [redirect, setRedirect] = useState(false);

  const { userRegisterUuid, userRegisterUuidResponse } = useUserRegisterUuid();
  const { uuid } = useParams();
  const handleFormSubmit = () => {
    userRegisterUuid(uuid, form);
  };

  useEffect(() => {
    if (userRegisterUuidResponse) {
      if (userRegisterUuidResponse.status === 200) {
        setShowSuccessAlert(true);
        setRedirect(true);
      } else {
        setShowErrorAlert(true);
      }
    }
  }, [userRegisterUuidResponse, setShowSuccessAlert, setShowErrorAlert]);

  if (redirect) {
    return <Navigate to="/login" />;
  }

  if ((ready && user))
    return <Navigate to={"/"} />;

  return (
    <>
      <div className="flex items-center justify-around">
        <div className="mt-32 py-9">
          <Card>
            <Container>
              <h1 className="text-4xl text-center font-bold pb-4">{t('Register')}</h1>
              <div className="flex flex-col gap-2">
                <InputField
                  required
                  label={t("First name")}
                  placeholder={t("First name")}
                  onChange={(e) =>
                    setForm({ ...form, first_name: e.target.value })
                  }
                  value={form.first_name}
                />

                <InputField
                  required
                  label={t("Last name")}
                  placeholder={t("Last name")}
                  onChange={(e) =>
                    setForm({ ...form, last_name: e.target.value })
                  }
                  value={form.last_name}
                />

                <InputField
                  required
                  type="password"
                  label={t("Password")}
                  placeholder={t("Password")}
                  onChange={(e) =>
                    setForm({ ...form, password: e.target.value })
                  }
                  value={form.password}
                />

                <span className="text-sm py-2">
                  {t('Already have an account? Sign in')}{" "}
                  <Link className="text-primary-700" to="/login">
                    {t('here')}
                  </Link>
                </span>

                <Button label={t("Register")} onClick={handleFormSubmit} />
              </div>
            </Container>
          </Card>
        </div>
      </div>
      {showErrorAlert && (
        <Alert
          message={t("Failed to create expense.")}
          type="error"
          duration={2000}
        />
      )}
      {showSuccessAlert && (
        <Alert
          message={t("Expense created successfully.")}
          type="success"
          duration={2000}
        />
      )}
    </>
  );
}
