import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

// Import your language files
import en from './translations/en.json';
import el from './translations/el.json';
import es from './translations/es.json';
import de from './translations/de.json';

i18n
    .use(LanguageDetector) // Detects the user's browser language
    .use(initReactI18next) // Initializes i18next with React support
    .init({
        resources: {
            en: { translation: en },
            el: { translation: el },
            es: { translation: es },
            de: { translation: de },
        },
        fallbackLng: 'en', // Default to English if the detected language is missing
        interpolation: {
            escapeValue: false, // React already escapes values, so we disable escaping
        },
        react: {
            useSuspense: false, // Prevents freezing while loading translations
        },
        detection: {
            order: ['navigator', 'htmlTag', 'localStorage', 'cookie'], // Prioritize browser language settings
            caches: ['localStorage', 'cookie'], // Cache the language selection
        },
    });

export default i18n;
