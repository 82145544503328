import { useDispatch } from "react-redux";
import { setDropdownisNull } from "../../actions/dropdownActions";
import useFetchCompany from "../../actions/hooks/useFetchCompany";
import React, { useEffect, useState } from "react";
import { useContext } from "react";
import { UserContext } from "../../context/UserContext";
import SelectField from "../layout/SelectField";
import { useTranslation } from "react-i18next";
const UsersDropdown = ({ onChange, report, label, width }) => {
	const { t } = useTranslation();
	const [users, setUsers] = useState([]);
	const [dropDownValue, setDropDownValue] = useState("");
	const companyData = useFetchCompany();
	const { user } = useContext(UserContext);
	const dispatch = useDispatch();
	useEffect(() => {
		if (companyData && companyData.users) {
			setUsers(companyData.users);
		}
	}, [companyData]);

	useEffect(() => {
		dispatch(setDropdownisNull(dropDownValue === ""));
	}, [dropDownValue, dispatch]);

	const handleDropdownChange = (value) => {
		setDropDownValue(value);
		onChange(value);

		// Check if the value is empty and dispatch accordingly
		dispatch(setDropdownisNull(value === ""));
	};

	return (
		<div>
			<label className="block uppercase tracking-wide text-xs font-bold mb-1">
				{label}
			</label>
			<SelectField onChange={(e) => handleDropdownChange(e.target.value)}>

				{/* Default empty option */}
				{!report && (
					<option value="">{t('Select administrator')}</option>
				)}

				{/* Option for the report assignee */}
				{report && report.assignee && report.assignee.first_name ? (
					<option value={report.assignee.id}>
						{user.id === report.assignee.id
							? `${user.first_name} ${user.last_name} (me)`
							: `${report.assignee.first_name} ${report.assignee.last_name} (${report.assignee.email})`}
					</option>
				) : null}

				{/* Mapping through users to create options */}
				{users
					.filter(
						(userElement) =>
							userElement.roles.some(
								(role) => role.name === "administrator"
							) &&
							(!report ||
								!report.assignee ||
								userElement.email !== report.assignee.email)
					)
					.map((userElement) => (
						<option key={userElement.id} value={userElement.id}>
							{userElement.first_name} {userElement.last_name} (
							{userElement.email})
						</option>
					))}

				{/* Option for the current user if not already assigned */}
				{report && user.id !== report.assignee.id &&
					user.roles.some(role => role.name === "administrator") && (
						<option key={user.id} value={user.id}>
							{user.first_name} {user.last_name} (me)
						</option>
					)}

			</SelectField>
		</div>
	);
};

export default UsersDropdown;
