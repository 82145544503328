import Card from "../elements/Card";
import ExpenseCreateOptionManually from "./ExpenseCreateOptionManually";
import { useTranslation } from "react-i18next";
export default function ExpenseNotFound() {
  const { t } = useTranslation();
  return (
    <Card>
      <div className="flex justify-center items-center py-16 px-16">
        <div className="flex flex-col items-center border rounded-lg p-6">
          <span className="font-bold text-xl">{t('You have no expenses')}</span>
          <ExpenseCreateOptionManually>
            <div className="flex flex-col items-center gap-4 py-4">
              <div className="w-64">
                <img
                  src="/images/receipt1024.png"
                  alt="image"
                  className="rounded-md w-full"
                />
              </div>
              <span className="hover:text-primary-400 font-semibold">
                {t('Create a new expense')}
              </span>
            </div>
          </ExpenseCreateOptionManually>
        </div>
      </div>
    </Card>
  );
}
