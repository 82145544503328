import ReportCreateOptionDateRange from "./ReportCreateOptionDateRange";
import ReportCreateOptionSelectExpenses from "./ReportCreateOptionSelectExpenses";
import DropDown from "../elements/DropDown";
import DropDownOptionWrapper from "../layout/DropDownOptionWrapper";
import { useTranslation } from "react-i18next";

const ReportCreateOptions = () => {
  const { t } = useTranslation();
  return (
    <DropDown label="New report">
      <DropDownOptionWrapper>
        <ReportCreateOptionDateRange>
          <span>{t('Select dates')}</span>
        </ReportCreateOptionDateRange>
      </DropDownOptionWrapper>
      <DropDownOptionWrapper>
        <ReportCreateOptionSelectExpenses>
          <span>{t('Select expenses')}</span>
        </ReportCreateOptionSelectExpenses>
      </DropDownOptionWrapper>
    </DropDown>
  );
};

export default ReportCreateOptions;
