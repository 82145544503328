import InputField from "../layout/InputField";
import Avatar from "../user/Avatar";
import { useContext, useEffect, useState } from "react";
import { UserContext } from "../../context/UserContext";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPaperPlane } from "@fortawesome/free-solid-svg-icons";
import CommentsList from "./CommentsList";
import useReportComment from "../../actions/hooks/useReportComment";
import { useRef } from "react";
import { useTranslation } from "react-i18next";

export default function CommentSection({ report }) {
  const { t } = useTranslation();
  const { user } = useContext(UserContext);
  const [message, setMessage] = useState("");
  const [rerender, setRerender] = useState(false);
  const messagesContainerRef = useRef(null);

  const { reportComment, reportCommentResponse } = useReportComment(report.id);

  const handleFormSubmit = (e) => {
    e.preventDefault();
    if (!message) return false;

    reportComment({ message });
  };

  useEffect(() => {
    if (reportCommentResponse && reportCommentResponse.status === 200) {
      setRerender((rerender) => !rerender);
      setMessage("");
    }
  }, [reportCommentResponse]);

  const [onChangeHeight, setOnChangeHeight] = useState(false);
  useEffect(() => {
    if (messagesContainerRef.current) {
      messagesContainerRef.current.scrollTop =
        messagesContainerRef.current.scrollHeight;
    }
  }, [onChangeHeight]);

  return (
    <>
      <div
        className="flex flex-col gap-4 px-2 py-4 text-sm overflow-y-auto max-h-96"
        ref={messagesContainerRef}
      >
        <CommentsList
          report_id={report.id}
          rerender={rerender}
          onChangeHeight={() => setOnChangeHeight(!onChangeHeight)}
        />
      </div>
      <div className="flex flex-row items-center w-full gap-2 px-2">
        <div>
          <Avatar user={user} hiddenTitle />
        </div>
        <form className="w-full" onSubmit={handleFormSubmit}>
          <InputField
            value={message}
            onChange={(e) => setMessage(e.target.value)}
            placeholder={t("Write something...")}
            className="flex py-2 px-4 rounded-full w-full border"
            icon={
              <button type="submit">
                <FontAwesomeIcon
                  icon={faPaperPlane}
                  className="text-lg pr-1 hover:cursor-pointer"
                />
              </button>
            }
          />
        </form>
      </div>
    </>
  );
}
