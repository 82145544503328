import Container from "../components/layout/Container";
import PageTitle from "./PageTitle";
import { useTranslation } from "react-i18next";

export default function ErrorPage() {
	const { t } = useTranslation();
	return (
		<>
			<div>
				<PageTitle />
			</div>
			<Container>
				<div className="flex flex-col items-center justify-center md:min-h-screen">
					<img
						src="/images/error404.png"
						alt="404 Error - Page Not Found"
						className="m-4 rounded-3xl shadow-2xl w-full max-w-2xl md:max-w-xl lg:max-w-2xl"
					/>
					<div className="text-center m-10 md:text-xl lg:text-2xl">
						{t('Looks like this page went over budget')}
					</div>
				</div>
			</Container>
		</>
	);
}
