import UsersList from "./UsersList";
import Modal from "../elements/Modal";
import { useDispatch, useSelector } from "react-redux";
import { openModal, closeModal } from "../../actions/modalActions";
import Card from "../elements/Card";
import { useState, useContext } from "react";
import { UserContext } from "../../context/UserContext";
import axios from "axios";
import InviteUser from "./InviteUser";
import Button from "../elements/Button";
import { setRerender } from "../../actions/rerenderAction";
import ToggleFilters from "../expenses/ToggleFilters";
import Alert from "../elements/Alert";
import { useTranslation } from "react-i18next";

export default function UsersEdit() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { user } = useContext(UserContext);

  // State for handling alerts
  const [showErrorAlert, setShowErrorAlert] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [showSuccessAlert, setShowSuccessAlert] = useState(false);

  const [initialEmails, setInitialEmails] = useState([]);
  const [userFiltersIsVisible, setUserFiltersIsVisible] = useState(false);

  const onEmailsChange = (emails) => {
    setInitialEmails(emails);
  };

  const handleOpenModal = () => {
    dispatch(
      openModal({
        modalType: "inviteUser",
        modalTitle: t("Invite a colleague"),
        modalBodyData: {},
        key: "inviteUserModal",
      })
    );
  };

  // Check current status of modal
  const modal = useSelector((store) => store.modal);

  // Handle invite users
  const inviteUsers = async () => {
    try {
      await axios.post("user/invite", {
        emails: initialEmails,
        company_id: user.company_id,
      });

      dispatch(setRerender());
      dispatch(closeModal());
      setShowSuccessAlert(true);
      setTimeout(() => setShowSuccessAlert(false), 2000);
    } catch (error) {
      if (error.response && error.response.status === 409) {
        // Handle duplicate email error
        setErrorMessage(error.response.data.message);
      } else {
        // Handle other errors
        setErrorMessage(t("An error occurred while sending invitations."));
      }
      setShowErrorAlert(true);
      setTimeout(() => setShowErrorAlert(false), 2000);
    }
  };

  return (
    <div>
      <div className="flex flex-row items-center justify-between">
        <ToggleFilters onChange={setUserFiltersIsVisible} />
        {user.user_roles.includes("administrator") ? (
          <Button label={t("Invite a user")} onClick={handleOpenModal} />
        ) : (
          <div></div>
        )}
      </div>

      {modal.isOpen && modal.key === "inviteUserModal" && (
        <Modal modalSubmit={inviteUsers}>
          <Card>
            <InviteUser
              onEmailsChange={onEmailsChange}
              initialEmails={initialEmails}
            />
          </Card>
        </Modal>
      )}
      <UsersList userFiltersIsVisible={userFiltersIsVisible} />

      {/* Render custom alert components */}
      {showErrorAlert && (
        <Alert message={errorMessage} type="error" duration={2000} />
      )}
      {showSuccessAlert && (
        <Alert message={t("Invitations sent successfully!")} type="success" duration={2000} />
      )}
    </div>
  );
}
