import { useDispatch, useSelector } from "react-redux";
import { closeModal } from "../../actions/modalActions";
import { useEffect, useRef, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";

const SideModal = ({ children, modalTitle }) => {
	const modalRef = useRef();
	const dispatch = useDispatch();
	const modal = useSelector((store) => store.modal);
	const [isVisible, setIsVisible] = useState(false);

	// Handle outside click to close the modal
	useEffect(() => {
		const handleClickOutside = (event) => {
			if (modalRef.current && !modalRef.current.contains(event.target)) {
				handleCloseModal();
			}
		};

		if (modal.isOpen) {
			document.addEventListener("mousedown", handleClickOutside);
			setIsVisible(true); // Start animation when opening
		} else {
			setIsVisible(false);
		}

		return () => {
			document.removeEventListener("mousedown", handleClickOutside);
		};
	}, [modal.isOpen]);

	const handleCloseModal = () => {
		setIsVisible(false); // Start closing animation
		setTimeout(() => {
			dispatch(closeModal());
		}, 300); // Wait for animation to finish before unmounting
	};

	if (!modal.isOpen) {
		return null;
	}

	return (
		<div className="fixed inset-0 z-50 bg-black bg-opacity-50 flex justify-end">
			{/* Sidebar container with animation */}
			<div
				ref={modalRef}
				className={`w-80 h-full bg-white shadow-lg transform transition-transform duration-300 ${isVisible ? "translate-x-0" : "translate-x-full"
					}`}
			>
				{/* Modal Header */}
				<div className="flex items-center justify-between p-4 border-b">
					<h3 className="text-lg font-semibold">{modalTitle}</h3>
					<button
						type="button"
						className="text-gray-400 hover:text-gray-600"
						onClick={handleCloseModal}
					>
						<FontAwesomeIcon icon={faTimes} className="text-lg" />
					</button>
				</div>

				{/* Modal Content */}
				<div className="p-4">{children}</div>
			</div>
		</div>
	);
};

export default SideModal;
