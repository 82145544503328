import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronUp, faChevronDown } from "@fortawesome/free-solid-svg-icons";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

export default function ToggleFilters({ onChange }) {
  const { t } = useTranslation();
  const [isVisible, setIsVisible] = useState(false);
  useEffect(() => {
    onChange(isVisible);
  }, [isVisible]);
  return (
    <div className="flex justify-between">
      <div className="flex items-center gap-2">
        <div
          className="flex items-center gap-2 py-1 px-4 rounded-full bg-white shadow hover:cursor-pointer hover:bg-gray-300"
          onClick={() => setIsVisible((isVisible) => !isVisible)}
        >
          <span className="font-semibold">{t('Filters')}</span>
          {isVisible ? (
            <FontAwesomeIcon icon={faChevronUp} />
          ) : (
            <FontAwesomeIcon icon={faChevronDown} />
          )}
        </div>
      </div>
    </div>
  );
}
