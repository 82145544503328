import React, { useEffect, useState } from "react";
import UserItem from "./UserItem";
import useFetchCompany from "../../actions/hooks/useFetchCompany";
import Modal from "../elements/Modal";
import Card from "../elements/Card";
import UserForm from "./UserForm";
import { useDispatch, useSelector } from "react-redux";
import { openModal } from "../../actions/modalActions";
import LoadError from "../elements/LoadError";
import UsersFilters from "./UsersFilters";
import ListItemWrapper from "../layout/ListItemWrapper";
import ListItemsWrapper from "../layout/ListItemsWrapper";
import OrderBy from "../expenses/OrderBy";
import { useTranslation } from "react-i18next";

export default function UsersList({ userFiltersIsVisible }) {
  const { t } = useTranslation();
  const [filters, setFilters] = useState({
    show: ["active", "archived"],
    regex: "",
  });

  const [orderBy, setOrderBy] = useState(null);
  const getNestedValue = (obj, path) => {
    const keys = path.split(".");
    return keys.reduce(
      (acc, key) => (acc && acc[key] !== "undefined" ? acc[key] : undefined),
      obj
    );
  };

  const orderByOptions = [
    { property: "first_name", label: t("First name") },
    { property: "last_name", label: t("Last name") },
    { property: "email", label: t("Email") },
    { property: "status", label: t("Status") },
  ];

  // Users state
  const [filteredUsers, setFilteredUsers] = useState([]);
  const [users, setUsers] = useState([]);

  const [loading, setLoading] = useState(true);
  const dispatch = useDispatch();
  const handleOpenModal = (id) => {
    setUserId(id);
    dispatch(
      openModal({
        modalType: "static",
        modalTitle: t("Edit user"),
        modalBodyData: {},
        key: "userFormModal",
      })
    );
  };

  // Check current status of modal
  const modal = useSelector((store) => store.modal);

  // Load company data
  const companyData = useFetchCompany();

  // Load users from company profile
  useEffect(() => {
    if (companyData && companyData.users) {
      setUsers(companyData.users);
      setLoading(false);
      setFilteredUsers(companyData.users);
    }
  }, [companyData, setUsers]);

  const [userId, setUserId] = useState(null);

  const handleFilterChange = () => {
    const tmp = users
      .filter((user) => {
        let success = false;

        if (
          (filters.show.includes("active") && user.status == "active") ||
          (filters.show.includes("archived") && user.status == "archived")
        ) {
          // If show_active is true but the user is not active, exclude the user
          success = true;
        }

        if (
          !user.first_name
            .toLowerCase()
            .includes(filters.regex.toLowerCase()) &&
          !user.last_name.toLowerCase().includes(filters.regex.toLowerCase()) &&
          !user.email.toLowerCase().includes(filters.regex.toLowerCase())
        ) {
          success = false;
        }

        // If no filters match, include the user by default
        return success;
      })
      .sort((a, b) => {
        if (!orderBy || !orderBy.type) {
          // If orderBy is not set, maintain the existing order
          return 0;
        }

        const aValue = getNestedValue(a, orderBy.type);
        const bValue = getNestedValue(b, orderBy.type);

        if (!orderBy.asc) {
          return aValue > bValue ? 1 : aValue < bValue ? -1 : 0;
        } else {
          return aValue < bValue ? 1 : aValue > bValue ? -1 : 0;
        }
      });

    setFilteredUsers(tmp);
  };

  useEffect(() => {
    handleFilterChange();
  }, [filters, orderBy]);

  if (loading) {
    return <LoadError />;
  }

  return (
    <div>
      <div className="flex flex-col gap-4">
        <div>
          {userFiltersIsVisible && (
            <>
              <div className="py-4">
                <UsersFilters
                  users={users}
                  filters={filters}
                  setFilters={setFilters}
                />
              </div>
            </>
          )}
        </div>
        <div className="border-t border-gray-300 pb-2"></div>
        <OrderBy onChange={setOrderBy} orderByOptions={orderByOptions} />
        <ListItemsWrapper>
          {filteredUsers.map((user, index) => (
            <ListItemWrapper key={index}>
              <UserItem data={user} onClick={handleOpenModal} />
            </ListItemWrapper>
          ))}
        </ListItemsWrapper>
      </div>
      {modal.isOpen && modal.key === "userFormModal" && (
        <Modal hiddenFooter>
          <Card>
            <UserForm userId={userId} />
          </Card>
        </Modal>
      )}
    </div>
  );
}
