import ExpenseCreateOptionManually from "./ExpenseCreateOptionManually";
import ExpenseCreateOptionScan from "./ExpenseCreateOptionScan";
import DropDown from "../elements/DropDown";
import DropDownOptionWrapper from "../layout/DropDownOptionWrapper";
import { useTranslation } from "react-i18next";

export default function ExpenseCreateOptions() {
  const { t } = useTranslation();
  return (
    <>
      <DropDown label={t("New expense")}>
        <DropDownOptionWrapper>
          <ExpenseCreateOptionManually>
            <span>{t('Create manually')}</span>
          </ExpenseCreateOptionManually>
        </DropDownOptionWrapper>
        <DropDownOptionWrapper>
          <ExpenseCreateOptionScan>
            <span>{t('Upload')}</span>
          </ExpenseCreateOptionScan>
        </DropDownOptionWrapper>
      </DropDown>
    </>
  );
}
