import InputField from "../layout/InputField";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

export default function InviteUser({ onEmailsChange, initialEmails }) {
  const { t } = useTranslation();
  const [showWarning, setShowWarning] = useState(true);
  const handleInputOnChange = (e) => {
    const inputValue = e.target.value;

    if (isEmailValid(inputValue)) {
      setShowWarning(false);
    } else {
      setShowWarning(true);
    }

    // Check if the input value includes a space or comma
    if (inputValue.includes(" ") || inputValue.includes(",")) {
      const [newEmail] = inputValue
        .split(/[,\s]+/)
        .filter((email) => email !== "");
      if (!isEmailValid(newEmail)) {
        return;
      }

      setEmails((prevEmails) => [...prevEmails, newEmail]);
      setValue("");
    } else {
      setValue(inputValue);
    }
  };

  // Remove email
  const removeEmailByKey = (index) => {
    setEmails(() => emails.filter((_, i) => i !== index));
  };

  // Email validation
  const isEmailValid = (inputValue) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(inputValue);
  };

  // List of emails
  const [emails, setEmails] = useState(initialEmails);

  // Single input current value
  const [value, setValue] = useState("");

  useEffect(() => {
    onEmailsChange(emails);
  }, [emails, onEmailsChange]);

  return (
    <div className="px-5">
      <InputField
        label={t("Email")}
        placeholder="user@company.com"
        onChange={handleInputOnChange}
        value={value}
      />
      <div className="pb-2">
        {showWarning ? (
          <div className="text-sm text-red-500">{t('Insert a valid email')}</div>
        ) : (
          <div className="text-sm text-green-500">{t('Hit space to add email')}</div>
        )}
      </div>
      <div className="flex flex-row gap-3 items-center max-w-200px flex-wrap overflow-hidden">
        {emails.map((email, index) => (
          <div
            key={index}
            className="py-2 px-4 border border-primary-200 rounded-full flex flex-row gap-3 items-center bg-primary-100"
          >
            <div>{email}</div>
            <div
              className="hover:cursor-pointer"
              onClick={() => removeEmailByKey(index)}
            >
              <FontAwesomeIcon icon={faTimes} />
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}
