import { useContext, useEffect, useState } from "react";
import InputField from "../layout/InputField";
import { UserContext } from "../../context/UserContext";
import Alert from "../elements/Alert";
import axios from "axios";
import Avatar from "../user/Avatar";
import Button from "../elements/Button";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { setRerender } from "../../actions/rerenderAction";
import LoadError from "../elements/LoadError";
import { useTranslation } from "react-i18next";

export default function UserForm({ userId }) {
	const { t } = useTranslation();
	const dispatch = useDispatch();
	const [userForm, setUserForm] = useState({
		first_name: "",
		last_name: "",
	});

	const [showSuccessAlert, setShowSuccessAlert] = useState(false);
	const [showErrorAlert, setShowErrorAlert] = useState(false);
	const { setUser } = useContext(UserContext);
	const [loading, setLoading] = useState(true);

	const fetchUser = async () => {
		try {
			let query = "user";
			if (userId) {
				query = "user/" + userId;
			}
			const response = await axios.get(query);
			setUserForm(response.data.user);
			setLoading(false);
		} catch (error) {
			// Do nothing
		}
	};

	const handleOnSubmit = async (e) => {
		e.preventDefault();

		try {
			let query = "user/update";
			if (userId) {
				query = "user/update/" + userId;
			}
			const response = await axios.patch(query, userForm);
			setUserForm(response.data.user);

			if (!userId) {
				setUser(response.data.user);
			}
			setShowSuccessAlert(true);
		} catch (error) {
			setShowErrorAlert(true);
		}

		dispatch(setRerender());
	};

	const rerender = useSelector((store) => store.rerender);
	useEffect(() => {
		fetchUser();
	}, [rerender]);

	useEffect(() => {
		const alertTimer = setTimeout(() => {
			setShowSuccessAlert(false);
			setShowErrorAlert(false);
		}, 2000);

		return () => {
			clearTimeout(alertTimer);
		};
	}, [showSuccessAlert, showErrorAlert]);

	if (loading) {
		return <LoadError />;
	}

	return (
		<div>
			<form
				className="w-full max-w-screen-lxg mx-auto p-8"
				onSubmit={handleOnSubmit}
			>
				<div className="flex flex-col md:flex-row md:items-center justify-center md:justify-between">
					<div className="w-full md:w-1/2 mb-4 md:mb-0">
						<div className="max-w-300px mx-auto mb-4">
							<Avatar user={userForm} />
						</div>
						<div className="text-center font-semibold whitespace-nowrap overflow-hidden">
							{t('Notifications will be sent to')}
						</div>
						<div className="text-center whitespace-nowrap overflow-hidden text-sm">
							{userForm.email}
						</div>
					</div>
					<div className="w-full md:w-1/2">
						<div className="flex flex-wrap -mx-2">
							<div className="w-full md:w-1/2 px-2 mb-4">
								<InputField
									label={t("First name")}
									required
									placeholder={t("First name")}
									onChange={(e) =>
										setUserForm({ ...userForm, first_name: e.target.value })
									}
									value={userForm.first_name}
								/>
							</div>
							<div className="w-full md:w-1/2 px-2 mb-4">
								<InputField
									label={t("Last name")}
									required
									onChange={(e) =>
										setUserForm({ ...userForm, last_name: e.target.value })
									}
									value={userForm.last_name}
								/>
							</div>
						</div>
						<div className="flex flex-col items-end">
							<Button label={t("Update")} />
						</div>
					</div>
				</div>
			</form>

			{showErrorAlert && (
				<Alert
					message={t("Failed to update user details.")}
					type="error"
					duration={2000}
				/>
			)}
			{showSuccessAlert && (
				<Alert
					message={t("User updated successfully.")}
					type="success"
					duration={2000}
				/>
			)}
		</div>
	);
}
