import Container from "../components/layout/Container";
import PageTitle from "./PageTitle";
import ReportsList from "../components/reports/ReportsList";
import ReportCreateOptions from "../components/reports/ReportCreateOptions";
import ReportsFilters from "../components/expenses/ReportsFilters";
import { useState } from "react";
import ToggleFilters from "../components/expenses/ToggleFilters";
import { useTranslation } from "react-i18next";
export default function ReportsPage() {
  const { t } = useTranslation();
  // Variables
  const [filtersIsVisible, setFiltersIsVisible] = useState(false);
  const [filters, setFilters] = useState({});

  const onChangeFilters = (filters) => {
    setFilters(filters);
  };
  return (
    <>
      <div>
        <PageTitle title={t("Reports")} />
      </div>
      <Container>
        <div className="flex items-center justify-between">
          <ToggleFilters onChange={setFiltersIsVisible} />
          <ReportCreateOptions />
        </div>
        <div className={`${filtersIsVisible ? "block" : "hidden"} z-10`}>
          <ReportsFilters onChange={onChangeFilters} />
        </div>
        <div className="border-t border-gray-300 pb-2"></div>
        <ReportsList
          selectedStates={filters.selectedStates}
          startDate={filters.startDate}
          endDate={filters.endDate}
        />
      </Container>
    </>
  );
}
