import { useTranslation } from "react-i18next";
const ReportsAnalysisShort = ({ reports }) => {
  const { t } = useTranslation();
  const statusFilters = [
    { status: "total", label: t("Total") },
    { status: "open", label: t("Open"), className: "bg-open" },
    { status: "processing", label: t("Processing"), className: "bg-processing" },
    { status: "reimbursed", label: t("Reimbursed"), className: "bg-reimbursed" },
    { status: "approved", label: t("Approved"), className: "bg-approved" },
    { status: "closed", label: t("Closed"), className: "bg-closed" },
  ];

  return (
    <div className="lg:max-h-64 lg:min-h-64 flex items-center justify-center">
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-3 gap-4 text-center w-full">
        {statusFilters.map((filter, index) => (
          <div
            key={index}
            className={`bg-white-500 text-white p-4 rounded-lg border border-gray-300 ${filter.className || ""
              }`}
          >
            <p
              className={`text-lg ${filter.label === "Total" || filter.label === "Σύνολο" || filter.label === "Gesamt" ? "text-black" : ""
                } text-center mx-auto`}
            >
              {filter.label}
            </p>

            <p className="text-lg font-bold text-center">
              {reports && (
                <>
                  <span
                    className={`${filter.label === "Total" || filter.label === "Σύνολο" || filter.label === "Gesamt" ? "text-black" : ""
                      }${(filter.status === "open" ||
                        filter.status === "processing") &&
                        reports.filter(
                          (report) => report.status === filter.status
                        ).length > 15
                        ? "bg-white text-orange-500 px-2 rounded"
                        : ""
                      } ${(filter.status === "open" ||
                        filter.status === "processing") &&
                        reports.filter(
                          (report) => report.status === filter.status
                        ).length > 20
                        ? "bg-white text-red-500 px-2 rounded"
                        : ""
                      }`}
                  >
                    {
                      reports.filter((report) => {
                        if (filter.status === "total") {
                          return report.status !== "deleted";
                        }
                        return report.status === filter.status;
                      }).length
                    }
                  </span>
                </>
              )}
            </p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default ReportsAnalysisShort;
