import Price from "../expenses/Price";
import Card from "../../components/elements/Card";
import ReportName from "./ReportName";
import { Link } from "react-router-dom";
import Button from "../elements/Button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash } from "@fortawesome/free-solid-svg-icons";
import useReportUpdate from "../../actions/hooks/useReportUpdate";
import { setRerender } from "../../actions/rerenderAction";
import { useDispatch } from "react-redux";
import { useEffect } from "react";
import useExpenseUpdate from "../../actions/hooks/useExpenseUpdate";
import Avatar from "../user/Avatar";
import { UserContext } from "../../context/UserContext";
import { useContext } from "react";
import StatePill from "../expenses/StatePill";
import FormattedDate from "../elements/FormattedDate";
import { useTranslation } from "react-i18next";

export default function ReportItem({ report }) {
	const { t } = useTranslation();
	const dispatch = useDispatch();
	const { reportUpdate, reportUpdateResponse } = useReportUpdate();
	const { expenseUpdate } = useExpenseUpdate();
	const { user } = useContext(UserContext);

	// Extract the first expense and its currency
	const firstExpense = report.expenses[0];
	const currency = firstExpense && firstExpense.price ? firstExpense.price.currency : null;

	// Calculate total cost while checking for valid expense amounts
	const totalCost = report.expenses.reduce((total, expense) => {
		if (!expense.price || expense.price.amount == null) {
			return total;
		}
		return total + parseFloat(expense.price.amount);
	}, 0);

	const price = {
		amount: totalCost,
		currency: "EUR",
	};

	// Re-set expenses state to unreported after successful report deletion
	useEffect(() => {
		if (reportUpdateResponse && reportUpdateResponse.status === 200) {
			report.expenses.forEach((expense) => {
				const updatedExpense = { ...expense, state: "unreported" };
				expenseUpdate(expense.id, updatedExpense);
			});
		}
	}, [report]);

	// Delete report
	const handleDelete = () => {
		reportUpdate(report.id, { ...report, status: "deleted" });
		dispatch(setRerender());
	};

	return (
		<div className="flex flex-col lg:flex-row items-center justify-between">
			<div className="flex flex-col lg:flex-row items-center lg:justify-between lg:space-x-4 lg:w-full gap-6">
				<div className="flex flex-row gap-10" title={report.name}>
					<div className="flex flex-col self-center">
						<FormattedDate date={report.created_at} showIcon={true} />
					</div>
					<div className="flex flex-col gap-2 items-center">
						<StatePill state={report.status} size="sm" />
						<span className="text-sm font-semibold">
							{report.name.length > 15 ? `${report.name.slice(0, 15)}..` : report.name}
						</span>
						<span className="font-semibold ">
							<Price price={price} />
						</span>
					</div>
				</div>
				<div className="flex flex-row gap-4 lg:space-x-4">
					<div className="flex flex-col items-center justify-center" title={report.creator.email}>
						<label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-1 text-base">
							{t('Created by')}:
						</label>
						<Avatar user={report.creator} hiddenTitle={false} shortTitle={report.creator.first_name.length + report.creator.last_name.length > 19} />
					</div>
					<div className="flex flex-col items-center justify-center" title={report.assignee.email}>
						<label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-1 text-base">
							{t('Assigned to')}:
						</label>
						<Avatar user={report.assignee} hiddenTitle={false} shortTitle={report.assignee.first_name.length + report.assignee.last_name.length > 19} />
					</div>
				</div>
				<div className="flex flex-row lg:flex-col items-center gap-2 lg:mt-0">
					{report.creator.id === user.id && report.status === "open" ? (
						<>
							<Link to={`/report/${report.id}`}>
								<Button width="w-32" label={t("Edit")} />
							</Link>
							<Button width="w-32" label={t("Delete")} children={<FontAwesomeIcon icon={faTrash} />} onClick={handleDelete} />
						</>
					) : (
						<Link to={`/report/${report.id}`}>
							<Button width="w-32" label={t("View")} />
						</Link>
					)}
				</div>
			</div>
		</div >
	);
}

