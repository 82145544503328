import React from "react";
import PropTypes from "prop-types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalendar } from "@fortawesome/free-solid-svg-icons";
import { useTranslation } from "react-i18next";

const FormattedDate = ({ date, format, showIcon }) => {
  const { t } = useTranslation();
  if (!date) {
    return null;
  }
  if (!format) {
    format = {
      month: "short",
      day: "numeric",
      year: "numeric",
    };
  }
  const formattedDate = new Intl.DateTimeFormat("en-US", {
    timeZone: "UTC",
    ...format,
  }).format(new Date(date));

  return (
    <span>
      {showIcon ? (
        <div className="flex flex-row gap-2">
          <div className="inline-flex items-center justify-center w-10 h-10 rounded-full border border-gray-200">
            <FontAwesomeIcon icon={faCalendar} className="text-gray-600" />
          </div>
          <div className="flex flex-col">
            <span className="font-semibold">{t('Date')}</span>
            <span className="text-sm">{formattedDate}</span>
          </div>
        </div>
      ) : (
        <span className="text-sm text-gray-600">{formattedDate}</span>
      )}
    </span>
  );
};

FormattedDate.propTypes = {
  date: PropTypes.oneOfType([PropTypes.instanceOf(Date), PropTypes.string]),
  format: PropTypes.object,
};

export default FormattedDate;
