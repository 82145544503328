import { useContext } from "react";
import { UserContext } from "../../context/UserContext";
import { Navigate } from "react-router-dom";
import PageTitle from "../PageTitle";
import Card from "../../components/elements/Card";
import UserForm from "../../components/user/UserForm";
import Container from "../../components/layout/Container";
import OverlayLoader from "../../components/elements/OverlayLoader";
import { useTranslation } from "react-i18next";
export default function AccountPage() {
  const { t } = useTranslation();
  const { ready, user, logout } = useContext(UserContext);

  if (!ready) {
    return <OverlayLoader />;
  }

  if (ready && !user) {
    return <Navigate to={"/login"} />;
  }

  const signOut = () => {
    logout();
    window.location.href = "/login";
  };

  return (
    <div>
      <div>
        <PageTitle title={t("Account")} />
      </div>

      <Container>
        <Card header={t("My account")}>
          <UserForm />
        </Card>
        <button className="underline" type="button" onClick={signOut}>
          {t('Sign out')}
        </button>
      </Container>
    </div>
  );
}
