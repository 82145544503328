import React, { useEffect } from "react";
import Card from "../elements/Card";
import Avatar from "./Avatar";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
	faTrash,
	faPenToSquare,
	faRotateLeft,
} from "@fortawesome/free-solid-svg-icons";
import { useContext } from "react";
import { UserContext } from "../../context/UserContext";
import useUserStatusUpdate from "../../actions/hooks/useUserStatusUpdate";
import { setRerender } from "../../actions/rerenderAction";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";

const UserItem = ({ data, onClick }) => {
	const { t } = useTranslation();
	const dispatch = useDispatch();
	const { user } = useContext(UserContext);
	const { userStatusUpdate, userStatusUpdateResponse } = useUserStatusUpdate();

	// Make user archived
	const handleArchiveUser = (user_id) => {
		userStatusUpdate(user_id, "archived");
	};

	// Make user active
	const handleRemoveArchive = (user_id) => {
		userStatusUpdate(user_id, "active");
	};

	useEffect(() => {
		if (userStatusUpdateResponse) {
			dispatch(setRerender());
		}
	}, [userStatusUpdateResponse]);

	return (
		<div className="hover:cursor-pointer">
			<div className="flex flex-col lg:flex-row items-center justify-between px-4">
				{/* Avatar & first_name / last_name / email */}
				<div className="flex flex-col items-center gap-4">
					<div className="max-w-44 min-w-44">
						<Avatar user={data} />
					</div>
					<div className="flex lg:flex-col gap-1">
						<p>{data.email}</p>
					</div>
				</div>

				{/* Role & actions */}
				<div className="flex lg:pt-0 pt-5 items-center">
					<div className="flex flex-col items-center">
						<span className="text-gray-500 text-sm font-semibold">{t('ROLE')}</span>{" "}
						<span className="text-sm font-semibold">
							{data.user_roles.includes("administrator") ? (
								<span>{t('ADMINISTRATOR')}</span>
							) : (
								<span>{t('USER')}</span>
							)}
						</span>
					</div>

					<div className="flex px-5 gap-5">
						{user.user_roles.includes("administrator") ? (
							<>
								<div className="group" onClick={() => onClick(data.id)}>
									<span className="text-gray-500 group-hover:text-primary-600 text-sm font-semibold">
										{t('EDIT')}
									</span>{" "}
									<FontAwesomeIcon
										className="text-gray-500 group-hover:text-primary-600"
										icon={faPenToSquare}
									/>
								</div>

								{data.status === "active" && (
									<div
										className="group"
										onClick={() => handleArchiveUser(data.id)}
									>
										<span className="text-gray-500 group-hover:text-red-500 text-sm font-semibold">
											{t('ARCHIVE')}
										</span>{" "}
										<FontAwesomeIcon
											className="text-gray-500 group-hover:text-red-500"
											icon={faTrash}
										/>
									</div>
								)}

								{data.status === "archived" && (
									<div
										className="group"
										onClick={() => handleRemoveArchive(data.id)}
									>
										<span className="text-gray-500 group-hover:text-red-500 text-sm font-semibold">
											{t('ACTIVATE')}
										</span>{" "}
										<FontAwesomeIcon
											className="text-gray-500 group-hover:text-red-500"
											icon={faRotateLeft}
										/>
									</div>
								)}
							</>
						) : null}
					</div>
				</div>
			</div>
		</div>
	);
};

export default UserItem;
