import StatePill from "../expenses/StatePill";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faTrash,
  faEdit,
  faMagnifyingGlass,
} from "@fortawesome/free-solid-svg-icons";
import FormattedDate from "../elements/FormattedDate";
import Price from "../expenses/Price";
import Button from "../elements/Button";
import useReportRemoveExpense from "../../actions/hooks/useReportRemoveExpense";
import { UserContext } from "../../context/UserContext";
import { useContext } from "react";
import { useDispatch } from "react-redux";
import Table from "../elements/Table";
import { setRerender } from "../../actions/rerenderAction";
import { useTranslation } from "react-i18next";

export default function ReportExpenseByCategoryList({
  categoryArray,
  report_id,
  handleOpenExpenseModal,
}) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { reportRemoveExpense } = useReportRemoveExpense(report_id);
  const handleRemoveExpense = (id) => {
    dispatch(setRerender());
    reportRemoveExpense({
      expense_id: id,
    });
    dispatch(setRerender());
  };
  const { user } = useContext(UserContext);
  const headers = [
    t("State"),
    t("Date"),
    t("Merchant"),
    t("Reimbursable"),
    t("Total"),
    "",
    "",
  ];

  const reportsListByCategory = categoryArray.map((category, index) => {
    const columnWidths = [
      "128px",
      "200px",
      "10px",
      "100px",
      "10px",
      "10px",
      "100px",
    ];
    let body = [];

    category.expenses.map((expense) => {
      body.push([
        <StatePill state={expense.state} size="sm" />,
        <FormattedDate date={expense.date} />,
        <div>{expense.merchant.name}</div>,
        <div>{expense.reimbursable ? t("Yes") : t("No")}</div>,
        <Price price={expense.price} />,
        <div
          className="hover:cursor-pointer hover:text-primary-600 text-primary-700 flex flex-col items-center"
          onClick={() => handleOpenExpenseModal(expense)}
        >
          {user.id === expense.user_id && expense.state === "open" ? (
            <FontAwesomeIcon icon={faEdit} />
          ) : (
            <FontAwesomeIcon icon={faMagnifyingGlass} />
          )}
        </div>,
        user.id === expense.user_id && expense.state === "open" && (
          <div className="flex justify-end">
            <Button
              label={t("Remove")}
              className=""
              onClick={() => {
                handleRemoveExpense(expense.id);
              }}
            >
              <FontAwesomeIcon icon={faTrash} />
            </Button>
          </div>
        ),
      ]);
    });

    return (
      <div key={index}>
        {
          <>
            <div className="flex flex-row justify-between">
              <span className="block uppercase pt-4 text-left font-bold">
                {category.headline}
              </span>
            </div>
            <div className="py-4">
              <div className="flex flex-col gap-2 px-4 py-2 border rounded-lg">
                <Table
                  headers={headers}
                  body={body}
                  columnWidths={columnWidths}
                />

                <span className="block uppercase font-bold flex flex-row">
                  Total:&nbsp;
                  <Price price={category.total} />
                </span>
              </div>
            </div>
          </>
        }
      </div>
    );
  });

  return <>{reportsListByCategory}</>;
}
