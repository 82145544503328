import React from "react";
import { useDispatch } from "react-redux";
import { openModal, closeModal } from "../../actions/modalActions";
import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCaretRight, faCheck } from "@fortawesome/free-solid-svg-icons";
import i18n from "../../i18n";
import SideModal from "./SideModal";

const LanguageSwitcher = () => {
	const dispatch = useDispatch();
	const { t } = useTranslation();
	const currentLanguage = i18n.language.split("-")[0] || "en"; // Normalize "en-US" to "en"

	const changeLanguage = (lang) => {
	
	 	// Save language to localStorage
		localStorage.setItem("language", lang)
	
		i18n.changeLanguage(lang);
		dispatch(closeModal());
		
	};

	const languageOptions = [
		{ label: "English", value: "en", flag: "/images/flag-en.png" },
		{ label: "Ελληνικά", value: "el", flag: "/images/flag-el.png" },
		{ label: "German", value: "de", flag: "/images/flag-de.png" },
		{ label: "Spanish", value: "es", flag: "/images/flag-es.png" },
	];
	return (
		<>
			{/* Language Selector Button */}
			<div
				className="flex flex-row justify-between items-center cursor-pointer p-2"
				onClick={() => dispatch(openModal("Language"))}
			>
				<div className="text-md">
					<span>{t("Language")}:</span> {languageOptions.find(lang => lang.value === currentLanguage)?.label}
				</div>
				<FontAwesomeIcon icon={faCaretRight} className="text-xl" />
			</div>

			{/* Side Modal */}
			<SideModal modalTitle={t("Language")}>
				{/* Language Options */}
				<div className="p-4">
					{languageOptions.map((lang) => (
						<div
							key={lang.value}
							className="flex items-center justify-between p-3 hover:bg-gray-100 cursor-pointer rounded-lg"
							onClick={() => changeLanguage(lang.value)}
						>
							<div className="flex items-center">
								<img src={lang.flag} alt={lang.label} className="w-6 h-4 mr-3" />
								<span className="text-sm">{lang.label}</span>
							</div>
							{currentLanguage === lang.value && (
								<FontAwesomeIcon icon={faCheck} className="text-green-500" />
							)}
						</div>
					))}
				</div>
			</SideModal>
		</>
	);
};

export default LanguageSwitcher;
