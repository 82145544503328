import { useEffect, useState, useContext } from "react";
import InputField from "../components/layout/InputField";
import useUserResetPasswordRequest from "../actions/hooks/useUserResetPasswordRequest";
import Button from "../components/elements/Button";
import { Navigate, Link } from "react-router-dom";
import Card from "../components/elements/Card";
import Container from "../components/layout/Container";
import Alert from "../components/elements/Alert";
import useShowAlert from "../actions/hooks/utility/useShowAlert";
import { UserContext } from "../context/UserContext";
import { useTranslation } from "react-i18next";
export default function ResetPasswordRequestPage() {
  const { t } = useTranslation();
  const [email, setEmail] = useState("");
  const { ready, user } = useContext(UserContext);

  const {
    showSuccessAlert,
    setShowSuccessAlert,
    showErrorAlert,
    setShowErrorAlert,
  } = useShowAlert();

  const [redirect, setRedirect] = useState(false);
  const { userResetPasswordRequest, userResetPasswordRequestResponse, status } = useUserResetPasswordRequest();
  const handleFormSubmit = () => {
    userResetPasswordRequest(email);
  };

  useEffect(() => {
    if (userResetPasswordRequestResponse) {
      if (userResetPasswordRequestResponse.status === 200) {
        setShowSuccessAlert(true);
        setTimeout(() => {
          setRedirect(true);
        }, 2000);
      } else {
        setShowErrorAlert(true);
      }
    }
  }, [userResetPasswordRequestResponse, setShowSuccessAlert, setShowErrorAlert, status]);

  if (redirect) {
    return <Navigate to="/login" />;
  }

  if ((ready && user))
    return <Navigate to={"/"} />;

  return (
    <>
      <div className="flex items-center justify-around">
        <div className="mt-32 py-9">
          <Card>
            <Container>
              <h1 className="text-4xl text-center font-bold pb-4">{t('Reset Password')}</h1>
              <div className="flex flex-col gap-2">

                <InputField
                  required
                  type="email"
                  label={t("Email")}
                  placeholder={t("Email")}
                  onChange={(e) =>
                    setEmail(e.target.value)
                  }
                  value={email}
                />

                <Button label={t("Reset")} onClick={handleFormSubmit} />
              </div>

              <div className="flex gap-1 text-gray-600 py-2">
                {t('Already have an account?')}
                <Link className="underline" to={"/login"}>
                  {t('Login')}
                </Link>
              </div>
            </Container>
          </Card>
        </div>
      </div>
      {showErrorAlert && (
        <Alert
          message={t("No user found with this email.")}
          type="error"
          duration={2000}
        />
      )}
      {showSuccessAlert && (
        <Alert
          message={t("Email has been sent successfuly.")}
          type="success"
          duration={2000}
        />
      )}
    </>
  );
}
